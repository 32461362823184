.App {
  text-align: center;
}

#root {
  overflow-x: hidden;
}

.h5Font {
  font-size: 1.25rem;
  font-weight: 500;
}